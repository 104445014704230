<script setup lang="ts">
import { get } from 'lodash'
import { computed, onMounted, ref } from 'vue'
import { useRoute, useRouter, RouterLink } from 'vue-router'
import { useSkeleton } from '@/modules/core/composables/useSkeleton'
import { useFetch as useFetchV3 } from '@/modules/core/stores/fetch'
import VSelectInvestor from '@/modules/investing/components/VSelectInvestor.vue'
import { useEntityStore } from '@/modules/investing/stores/better-entity-store'
import {
  ActionsGroup,
  ActionsMenu,
  ActionItem,
  VBadge,
  VButtonGroup,
  VIcon,
  VSection,
  VTable,
  VModal,
} from '@/modules/shared/components'
import { sumMoney, initialMoney } from '@/modules/shared/utils/money'
import { rails_url } from '@/modules/shared/utils/rails'
import { distributionMethodColumns } from '@/modules/shared/utils/columns/distributionMethod'
import { format } from '@/modules/shared/utils/v-table'
import DistributionsBarChart from '@/modules/investing/components/distributions-bar-chart.vue'
import { useModal } from '@/modules/shared/composables/use-modal'
import EntityDistributionMarkAsPaidForm from '@/modules/investing/components/entities/distributions/markAsPaidForm.vue'
import EntityLayout from '@/modules/investing/components/entities/entity-layout.vue'
import { VButton } from '@/modules/shared/components'
import { investorPath } from '@/modules/investing/utils/investor'
import { sentToInvestorDistributions } from '@/modules/investing/utils/distribution'
import { distribution_columns } from '@/modules/investing/config/columns'
import { useAuthStore } from '@/modules/auth/stores/auth-store'

///////////////////////////////////////////////////////////////////////////////
// Distributions
///////////////////////////////////////////////////////////////////////////////

const entityStore = useEntityStore()
const entity = computed(() => entityStore.entity)

const expandedDistributions = computed(() => {
  const distributions = entityStore.listDistributions
  const filteredDistributions = sentToInvestorDistributions(distributions)
  return (
    filteredDistributions.map((distribution) => {
      return {
        ...distribution,
        company: entityStore.getCompany(distribution.company_id),
        shareholder: entityStore.getShareholder(distribution.shareholder_id, distribution.shareholder_type),
      }
    }) || []
  )
})

const selectedCids = computed(() =>
  entityStore.selectedInvestorKeys.map((keys) => JSON.parse(keys).reverse().join(':')),
)

///////////////////////////////////////////////////////////////////////////////
// Insights
///////////////////////////////////////////////////////////////////////////////

// collect and transform into camelCase
const entityInsights = computed(() => {
  const insights = entityStore.entity?.insights

  if (!insights) {
    return {
      initialValue: initialMoney,
      currentValue: initialMoney,
      commitment: initialMoney,
      committed: initialMoney,
      commitmentRemaining: initialMoney,
      cashPosition: initialMoney,
      hurdleRemaining: initialMoney,
      calledOutstandingTotal: initialMoney,
      calledOutstandingCapital: initialMoney,
      calledOutstandingManagementFee: initialMoney,
      calledOutstandingOtherFee: initialMoney,
      calledSettledTotal: initialMoney,
      calledSettledCapital: initialMoney,
      calledSettledManagementFee: initialMoney,
      calledSettledOtherFee: initialMoney,
      distributedCarriedInterest: initialMoney,
      distributedHurdleRemaining: initialMoney,
      distributedInterest: initialMoney,
      distributedNet: initialMoney,
      distributedOtherFee: initialMoney,
      distributedPreferredReturn: initialMoney,
      distributedProfit: initialMoney,
      distributedReturnOfCapital: initialMoney,
      dpi: 0,
      moic: 0,
      roi: 0,
      rvpi: 0,
      tvpi: 0,
      xirr: 0,
    }
  }

  return {
    initialValue: insights.initial_value,
    currentValue: insights.current_value,

    commitment: insights.commitment,
    committed: insights.committed,
    commitmentRemaining: insights.commitment_remaining,
    cashPosition: insights.cash_position,
    hurdleRemaining: insights.hurdle_remaining,

    calledOutstandingTotal: insights.called_outstanding_total,
    calledOutstandingCapital: insights.called_outstanding_capital,
    calledOutstandingManagementFee: insights.called_outstanding_management_fee,
    calledOutstandingOtherFee: insights.called_outstanding_other_fee,
    calledSettledTotal: insights.called_settled_total,
    calledSettledCapital: insights.called_settled_capital,
    calledSettledManagementFee: insights.called_settled_management_fee,
    calledSettledOtherFee: insights.called_settled_other_fee,

    distributedCarriedInterest: insights.distributed_carried_interest,
    distributedHurdleRemaining: insights.distributed_hurdle_remaining,
    distributedInterest: insights.distributed_interest,
    distributedNet: insights.distributed_net,
    distributedOtherFee: insights.distributed_other_fee,
    distributedPreferredReturn: insights.distributed_preferred_return,
    distributedProfit: insights.distributed_profit,
    distributedReturnOfCapital: insights.distributed_return_of_capital,

    dpi: insights.dpi,
    moic: insights.moic,
    roi: insights.roi,
    rvpi: insights.rvpi,
    tvpi: insights.tvpi,
    xirr: insights.xirr,
  }
})

const distributionTotals = computed(() => {
  return {
    carriedInterest: entityInsights.value.distributedCarriedInterest,
    hurdleRemaining: entityInsights.value.distributedHurdleRemaining,
    interest: entityInsights.value.distributedInterest,
    net: entityInsights.value.distributedNet,
    otherFee: entityInsights.value.distributedOtherFee,
    preferredReturn: entityInsights.value.distributedPreferredReturn,
    profit: entityInsights.value.distributedProfit,
    returnOfCapital: entityInsights.value.distributedReturnOfCapital,
  }
})

///////////////////////////////////////////////////////////////////////////////
// Actions
///////////////////////////////////////////////////////////////////////////////

const { entity_id, slug } = useRoute().params as { entity_id: string; slug: string }
const slideInFormModal = useModal()
const selected_distribution = ref(null)

const deleteDistribution = async (distributionId: string) => {
  if (!window.confirm('Are you sure?')) return
  await useFetchV3(`/${slug}/investing/b/entities/${entity_id}/distributions/${distributionId}/remove`).post()
  await fetch()
}

const fetch = async (shareholder_cids = null) => {
  showSkeleton()
  await entityStore.fetchDistributions(entity_id, { slug, shareholder_cids })
  hideSkeleton()
}

const markPaymentAsUnpaid = async (distributionId: string) => {
  await useFetchV3(
    `/${slug}/investing/b/entities/${entity_id}/distributions/${distributionId}/mark-payment-as-unpaid`,
  ).post()
  await fetch(selectedCids.value)
}

const openPayDistribution = (distributionId: string) => {
  selected_distribution.value = entityStore.getDistribution(distributionId)
  slideInFormModal.open()
}

const payDistribution = async (payload: { id: string; paid_date: string }) => {
  await useFetchV3(`/${slug}/investing/b/entities/${entity_id}/distributions/${payload.id}/mark-payment-as-paid`).post({
    distribution: payload,
  })
  await fetch(selectedCids.value)

  slideInFormModal.close()
}

const onFilter = async () => {
  await fetch(selectedCids.value)
}

///////////////////////////////////////////////////////////////////////////////
// Authorization
///////////////////////////////////////////////////////////////////////////////

const authStore = useAuthStore()
const isAdmin = computed(
  () => authStore.is_site_or_group_admin || !!entityStore.getAdmin(authStore.current_user.investor_id),
)

///////////////////////////////////////////////////////////////////////////////
// Rails Utils
///////////////////////////////////////////////////////////////////////////////

const createCompanyUrl = (id: string) => {
  return `${rails_url()}/companies/${id}`
}

///////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////

const router = useRouter()
const { skeleton, hideSkeleton, showSkeleton } = useSkeleton()

onMounted(async () => {
  await fetch()
})
</script>

<template>
  <EntityLayout selectedTab="distributions">
    <VSection class="flex items-center justify-between">
      <VButtonGroup>
        <VSelectInvestor
          v-model="entityStore.selectedInvestorKeys"
          :investors="entityStore.listShareholders"
          :onClose="onFilter"
        />
        <template v-if="isAdmin">
          <RouterLink :to="{ name: 'investing.distributions' }">
            <VButton :active="true">
              <span>Sent to investors</span>
            </VButton>
          </RouterLink>
          <RouterLink :to="{ name: 'investing.distributions.recycled' }">
            <VButton>
              <span>Recycled Funds</span>
            </VButton>
          </RouterLink>
          <RouterLink :to="{ name: 'investing.portfolio.distributions' }">
            <VButton>
              <span>Received from portfolio</span>
            </VButton>
          </RouterLink>
        </template>
      </VButtonGroup>
      <RouterLink
        :to="{
          name: 'investing.disbursement.new',
          params: { entity_type: entity?.entity_type, entity_id: entity?.id },
        }"
        v-if="entity"
      >
        <VButton size="md" variant="v-blue">
          <div class="mr-1 flex items-center space-x-2">
            <div><VIcon name="plus" /></div>
            <div>Distribution</div>
          </div>
        </VButton>
      </RouterLink>
    </VSection>
    <VSection>
      <DistributionsBarChart :distributionTotals="distributionTotals" :skeleton="skeleton" />
    </VSection>
    <VSection>
      <VTable
        :columns="distribution_columns"
        :items="expandedDistributions"
        :name="`entities-${1}-distributions`"
        :skeleton="skeleton"
      >
        <template #shareholder.name="{ item: distribution }">
          <RouterLink
            class="text-[#3b88af] underline decoration-[#3b88af]/50 hover:text-gray-900 hover:decoration-gray-900/50"
            :to="investorPath(get(distribution, 'shareholder.cid'))"
          >
            {{ get(distribution, 'shareholder.name') }}
          </RouterLink>
        </template>
        <template #company.name="{ item: distribution }">
          <a class="hyperlink" :href="createCompanyUrl(distribution.company_id)">
            {{ get(distribution, 'company.name') }}
          </a>
        </template>
        <template #payment_status="{ item: distribution }">
          {{ `${distribution.payment_status} ${format(distribution.payment_status_marked_at || undefined, 'data')}` }}
        </template>
        <template #type="{ item: distribution }">
          <VBadge class="inline-flex items-center space-x-1" color="v-green" size="xxs">
            <VIcon name="arrow_narrow_left" class="inline-block h-3 w-3" />
            <span>
              {{ get(distribution, 'type') }}
            </span>
          </VBadge>
        </template>
        <template #actions="{ item: distribution }">
          <ActionsMenu v-if="distribution.type !== 'transfer distribution'">
            <ActionsGroup>
              <ActionItem
                @click="
                  () =>
                    router.push({
                      name: 'investing.disbursement.edit',
                      params: {
                        entity_type: entity?.entity_type,
                        entity_id: entity?.id,
                        disbursement_id: distribution.batch_id,
                      },
                    })
                "
                text="Edit"
                tag="a"
              />
              <ActionItem
                v-if="distribution.payment_status === 'paid'"
                text="Mark as Pending"
                @click="markPaymentAsUnpaid(distribution.id)"
              />
              <ActionItem v-else text="Mark as Paid" @click="openPayDistribution(distribution.id)" />
              <ActionItem text="Remove" @click="deleteDistribution(distribution.id)" />
            </ActionsGroup>
          </ActionsMenu>
        </template>
      </VTable>
      <VModal :modalStore="slideInFormModal" :has_footer="false">
        <template #main>
          <EntityDistributionMarkAsPaidForm :onSubmit="payDistribution" :distribution="selected_distribution" />
        </template>
      </VModal>
    </VSection>
  </EntityLayout>
</template>
