import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useFetch } from '@/modules/shared/composables/use-fetch'
import { addItem, addItems, clearItems } from '@/modules/shared/utils/store'
import { CID } from '@/modules/shared/utils/store.types'
import { Money } from '@/modules/shared/utils/money'
import { useNotificationStore } from '@/modules/shared/stores/notification-store'

///////////////////////////////////////////////////////////////////////////////
// Types
///////////////////////////////////////////////////////////////////////////////

type ID = number | string

export type Deal = {
  id: ID
  capital_goal: Money
  committed: Money
  company: {
    id: ID
    name: string
    elevator_pitch: string
    logo: string
  }
  investment_type: string
}

///////////////////////////////////////////////////////////////////////////////
// Store
///////////////////////////////////////////////////////////////////////////////

export const useDealStore = defineStore('dashboard/dealStore', () => {
  const route = useRoute()
  const notificationStore = useNotificationStore()
  const baseUrl = computed(() => `/${route.params.slug}/deals`)

  const items = ref([])
  const deals = computed(() => items.value)

  const fetchDeals = async (config: { is_featured?: boolean; limit?: number; order?: string } = {}) => {
    const params = new URLSearchParams()

    Object.entries(config).forEach(([key, value]) => {
      if (value !== undefined) {
        params.append(key, value.toString())
      }
    })

    const url = `${baseUrl.value}?${params.toString()}`

    const { data, error } = await useFetch(url).get().json<{ data: Deal[] }>()

    if (error.value) {
      // TODO: handle error (e.g., display a message to the user)
      console.error(error.value)
      notificationStore.enqueue('error', 'Deals failed to load')
      return
    }

    items.value = data.value.data
  }

  return {
    items,
    deals,

    fetchDeals,
  }
})
