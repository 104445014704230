<script setup lang="ts">
import { computed, watch } from 'vue'
import { isEqual, sortBy } from 'lodash'
import { parseKey, stringifyKey } from '@/modules/core/stores/kv'
import { type Investor } from '@/modules/investing/stores/better-entity-store'
import { VButton, VDropdownImproved, VIcon } from '@/modules/shared/components'

const model = defineModel({ type: Array })

const props = defineProps<{
  investors: Investor[]
  aligned?: string
  onClose?: any
}>()

const options = computed(() => {
  return props.investors
    .map((i) => ({
      value: stringifyKey([i.id, i.type]),
      text: i.name,
    }))
    .sort((a, b) => a.text.localeCompare(b.text))
})

const viewingAs = computed(() => {
  const length = model.value.length

  if (length === props.investors.length) {
    return 'all investors'
  }

  if (length === 1) {
    return props.investors.find((i) => {
      const [id, type] = parseKey(model.value[0])
      return i.id === id && i.type === type
    }).name
  }

  return `${length} investors`
})

watch(options, (oldOptions, newOptions) => {
  if (oldOptions.length !== newOptions.length) {
    model.value = options.value.map((option) => option.value)
  }
})

const onClose = (valuesBefore, valuesAfter) => {
  const areValuesChanged = !isEqual(sortBy(valuesBefore), sortBy(valuesAfter))
  if (props.onClose && areValuesChanged) props.onClose()
}
</script>

<template>
  <VDropdownImproved v-model="model" class="z-50" :aligned="aligned" :onClose="onClose" :options="options">
    <VButton size="md">
      <div class="flex items-center space-x-2">
        <div>Filter by {{ viewingAs }}</div>
        <VIcon name="chevron_selector_vertical" />
      </div>
    </VButton>
  </VDropdownImproved>
</template>
