<script setup lang="ts">
import { get } from 'lodash'
import { ref, onMounted, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useFetch } from '@/modules/core/composables/useFetch'
import { useSkeleton } from '@/modules/core/composables/useSkeleton'
import { VButton, VDropdownImproved, VIcon, VTable, VSection } from '@/modules/shared/components'
import TheLayout from '@/modules/shared/layouts/the-layout.vue'

///////////////////////////////////////////////////////////////////////////////

const { skeleton, hideSkeleton, showSkeleton } = useSkeleton()

///////////////////////////////////////////////////////////////////////////////
// Data fetching
///////////////////////////////////////////////////////////////////////////////

interface FilingLocator {
  id: number
  fileable_id: number
  fileable_type: string
  fileable: {
    id: number
    name: string
    legal_name: string
  }
  group: {
    id: number
    name: string
    subdomain: string
  }
  k1_distribution_status: string
  return_status: string
  tax_year: number
}

const filingLocators = ref<FilingLocator[]>([])

const fetch = async () => {
  showSkeleton()
  const data = await useFetch(`/v4/all/tax/filing-locators?tax_year=${selectedTaxYear.value}`).get<FilingLocator[]>()
  filingLocators.value = data
  hideSkeleton()
}

onMounted(async () => {
  await fetch()
})

///////////////////////////////////////////////////////////////////////////////
// Tax year selection
///////////////////////////////////////////////////////////////////////////////

const taxYearOptions = [
  { label: '2024', value: '2024' },
  { label: '2023', value: '2023' },
  { label: '2022', value: '2022' },
]

const route = useRoute()
const currentYear = new Date().getFullYear() - 1
const selectedTaxYear = ref(route.query.year || currentYear)

watch(
  () => selectedTaxYear.value,
  async () => await fetch(),
  { immediate: true },
)
</script>

<template>
  <TheLayout>
    <VSection label="Tax Tracker">
      <div class="z-50 flex items-center justify-between">
        <div class="mb-6 flex items-center space-x-2">
          <!--VDropdownImproved v-model="selectedTaxYear" :multi="false" aligned="left" :options="taxYearOptions">
            <VButton size="md">
              <div class="flex items-center space-x-2">
                <div>Filter by year {{ selectedTaxYear }}</div>
                <VIcon name="chevron_selector_vertical" />
              </div>
            </VButton>
          </VDropdownImproved-->
        </div>
      </div>
      <VTable
        :columns="[
          {
            key: 'fileable.name',
            name: 'Name',
            type: 'string',
            align: 'left',
            fixed: true,
            is_visible: true,
          },
          {
            key: 'fileable.legal_name',
            name: 'Legal name',
            type: 'string',
            align: 'left',
            is_visible: false,
          },
          {
            key: 'group.name',
            name: 'Group',
            type: 'string',
            align: 'left',
            is_visible: true,
          },
          {
            key: 'return_status',
            name: 'Return status',
            type: 'string',
            align: 'left',
            is_visible: true,
          },
          {
            key: 'k1_distribution_status',
            name: 'K-1 distribution status',
            type: 'string',
            align: 'left',
            is_visible: true,
          },
          {
            key: 'tax_year',
            name: 'Tax year',
            type: 'string',
            align: 'left',
            is_visible: true,
          },
        ]"
        :items="filingLocators"
        :name="`all-tax-filing-locators-${selectedTaxYear}`"
        :skeleton="skeleton"
      >
        <template #fileable.name="{ item }">
          <div>
            <RouterLink
              class="cursor-pointer whitespace-break-spaces font-medium text-[#51889B] underline decoration-[#51889B]/50"
              :to="`/${get(item, 'group.subdomain')}/tax/entities`"
            >
              {{ get(item, 'fileable.name') }}
            </RouterLink>
          </div>
          <div class="mt-0.5">{{ get(item, 'fileable.legal_name') }}</div>
        </template>
      </VTable>
    </VSection>
  </TheLayout>
</template>

<style></style>
