<script setup lang="ts">
import { get } from 'lodash'
import { computed, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useSkeleton } from '@/modules/core/composables/useSkeleton'
import { useFetch as useFetchV3 } from '@/modules/core/stores/fetch'
import { useEntityStore, CommitmentExpanded } from '@/modules/investing/stores/better-entity-store'
import { calculate_aggregate, format as data_format } from '@/modules/shared/utils/v-table'
import { flatten } from 'flat'
import { ActionsGroup, ActionsMenu, ActionItem, VButton, VIcon, VTable, VModal } from '@/modules/shared/components'
import { useModal } from '@/modules/shared/composables/use-modal'
import EntityInvestorCommitmentForm from '@/modules/investing/components/entities/investors/commitmentForm.vue'
import ReassignCommitmentForm from '@/modules/investing/components/entities/investors/reassignCommitmentForm.vue'
import AssignOwnershipForm from '@/modules/investing/components/entities/investors/assignOwnershipForm.vue'
import EntityLayout from '@/modules/investing/components/entities/entity-layout.vue'
import { useAuthStore } from '@/modules/auth/stores/auth-store'
import { entity_investor_columns } from '@/modules/investing/config/columns'

///////////////////////////////////////////////////////////////////////////////
// Commitments
///////////////////////////////////////////////////////////////////////////////

const router = useRouter()
const entityStore = useEntityStore()

// Expansion allows us to add additional data to the commitment for the VTable component
// This is currently required in order to search and sort by expanded data
// NOTE run this before filtering to reduce the number of times we run this function
// NOTE expansion could be handled by the API, however, doing it client side simplifies the API
// QUESTION should we move this to the store?
const expandedCommitments = computed(() => entityStore.listCommitments)

///////////////////////////////////////////////////////////////////////////////
// Actions
///////////////////////////////////////////////////////////////////////////////

const { entity_id, slug } = useRoute().params as { entity_id: string; slug: string }
const selected_commitment = ref(null)

const fetch = async () => {
  await entityStore.fetchCommitments(entity_id, { slug })
}

const deleteCommitment = async (commitmentId: string) => {
  if (!window.confirm('Are you sure?')) return
  await useFetchV3(`/${slug}/investing/b/entities/${entity_id}/commitments/${commitmentId}/remove`).post()
  await fetch()
}

///////////////////////////////////////////////////////////////////////////////
// Commitment Form
///////////////////////////////////////////////////////////////////////////////

const commitmentFormModal = useModal()
const addCommitment = () => {
  selected_commitment.value = null
  commitmentFormModal.open()
}
const editCommitment = (commitment_id: string) => {
  selected_commitment.value = expandedCommitments.value.find((c) => c.id === commitment_id)
  commitmentFormModal.open()
}
const submitCommitment = async (payload) => {
  const url = payload.id
    ? `/${slug}/investing/b/entities/${entity_id}/commitments/${payload.id}`
    : `/${slug}/investing/b/entities/${entity_id}/commitments`

  await useFetchV3(url).post({ commitment: payload })
  await fetch()
}
const disabledInvestorCIDs = computed((): string[] =>
  [entityStore.entity.cid, entityStore.listInvestors.map((investor) => investor.cid)].flat(),
)

///////////////////////////////////////////////////////////////////////////////
// Reassign Form
///////////////////////////////////////////////////////////////////////////////

const reassignCommitmentFormModal = useModal()
const reassignCommitment = (commitment_id: string) => {
  selected_commitment.value = expandedCommitments.value.find((c) => c.id === commitment_id)
  reassignCommitmentFormModal.open()
}
const submitReassignCommitment = async (payload: {
  commitment_id: string
  from_investor_id: string
  to_investor_id: string
}) => {
  await useFetchV3(`/${slug}/investing/b/entities/${entity_id}/commitments/${payload.commitment_id}/reassign`).post(
    payload,
  )
  await fetch()
}

///////////////////////////////////////////////////////////////////////////////
// Assign Ownership Form
///////////////////////////////////////////////////////////////////////////////

const assignOwnershipFormModal = useModal()

const assignOwnership = (commitment_id: string) => {
  selected_commitment.value = expandedCommitments.value.find((c) => c.id === commitment_id)
  assignOwnershipFormModal.open()
}

const submitAssignOwnership = async (payload: { id: string; investor_id: string; ownership_override: number }) => {
  await useFetchV3(`/${slug}/investing/b/entities/${entity_id}/commitments/${payload.id}/update-ownership`).post({
    commitment: payload,
  })
  await fetch()
}

///////////////////////////////////////////////////////////////////////////////
// Authorization
///////////////////////////////////////////////////////////////////////////////

const authStore = useAuthStore()
const isAdmin = computed(
  () => authStore.is_site_or_group_admin || !!entityStore.getAdmin(authStore.current_user.investor_id),
)

///////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////

const { skeleton, hideSkeleton } = useSkeleton()

onMounted(async () => {
  await fetch()
  hideSkeleton()
})
</script>

<template>
  <EntityLayout selectedTab="investors">
    <div class="-mt-7 mb-5 flex justify-end">
      <VButton :click="addCommitment" size="md" variant="v-blue" v-if="isAdmin">
        <div class="mr-1 flex items-center space-x-2">
          <div><VIcon name="plus" /></div>
          <div>Add Investor</div>
        </div>
      </VButton>
    </div>
    <VTable
      :columns="entity_investor_columns(authStore.is_site_admin)"
      :items="expandedCommitments"
      :name="`entities-${1}-commitments`"
      :skeleton="skeleton"
    >
      <template #shareholder.name="{ item: commitment }">
        <RouterLink
          class="hyperlink"
          :to="
            get(commitment, 'shareholder_type') === 'InvestorSet'
              ? {
                  name: 'investing.other-entity.overview',
                  params: { other_entity_id: commitment.shareholder_id },
                }
              : { name: 'investing.individual-overview', params: { individual_id: commitment.shareholder_id } }
          "
          >{{ get(commitment, 'shareholder.name') }}</RouterLink
        >
      </template>
      <template #ownership="{ item: commitment }">
        {{ data_format(flatten(commitment)['ownership'], 'percent') }}
      </template>
      <template #tfoot.ownership="{ items: commitments, column }">
        <div :class="{ 'text-red-600': (calculate_aggregate(commitments, column) as number) > 1 }">
          {{ data_format(calculate_aggregate(commitments, column), 'percent') }}
        </div>
      </template>
      <template #actions="{ item: commitment }">
        <ActionsMenu v-if="isAdmin">
          <ActionsGroup>
            <ActionItem tag="button" text="Modify" @click="() => editCommitment(commitment.id)" />
            <ActionItem tag="button" text="Reassign" @click="() => reassignCommitment(commitment.id)" />
            <ActionItem tag="button" text="Remove" @click="() => deleteCommitment(commitment.id)" />
            <ActionItem tag="button" text="Assign Ownership" @click="() => assignOwnership(commitment.id)" />
          </ActionsGroup>
        </ActionsMenu>
      </template>
    </VTable>
    <VModal :modalStore="commitmentFormModal" :has_footer="false">
      <template #main>
        <EntityInvestorCommitmentForm
          :entity="entityStore.entity"
          :modalUtil="commitmentFormModal"
          :onSubmit="submitCommitment"
          :commitment="selected_commitment"
          :disabledInvestorCIDs="disabledInvestorCIDs"
        />
      </template>
    </VModal>
    <VModal :modalStore="reassignCommitmentFormModal" :has_footer="false">
      <template #main>
        <ReassignCommitmentForm
          :modalUtil="reassignCommitmentFormModal"
          :onSubmit="submitReassignCommitment"
          :commitment="selected_commitment"
          :disabledInvestorCIDs="disabledInvestorCIDs"
        />
      </template>
    </VModal>
    <VModal :modalStore="assignOwnershipFormModal" :has_footer="false">
      <template #main>
        <AssignOwnershipForm
          :modalUtil="assignOwnershipFormModal"
          :onSubmit="submitAssignOwnership"
          :commitment="selected_commitment"
        />
      </template>
    </VModal>
  </EntityLayout>
</template>
