<script setup lang="ts">
import { ref } from 'vue'
import { VTextField, VButton, VSection } from '@/modules/shared/components'
import { CommitmentExpanded } from '../../../stores/better-entity-store'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { useExtendedI18n } from '@/i18n'

const props = defineProps<{
  commitment: CommitmentExpanded
  modalUtil: any
  onSubmit: any
}>()

const { n } = useExtendedI18n()
const loading = ref(false)

const assignCommitmentDataToForm = () => {
  if (!props.commitment?.id) return { ...initialState }

  return {
    id: props.commitment.id,
    investor_cid: props.commitment.shareholder.cid,
    ownership_override: props.commitment.ownership_override ? props.commitment.ownership_override * 100 : null,
  }
}
const resetForm = () => {
  v$.value.$reset()
  form.value = { ...initialState }
}
const initialState = {
  id: null,
  investor_cid: null,
  ownership_override: null,
}

const form = ref(assignCommitmentDataToForm())
const rules = {
  id: { required },
  investor_cid: { required },
  ownership_override: { required },
}
const v$ = useVuelidate(rules, form, { $lazy: true })

const submitForm = async () => {
  const valid = await v$.value.$validate()
  if (!valid) return

  loading.value = true

  // backend api expects investor_id, but in the current_implementation it actually passes CID not ID
  const payload = { ...form.value, investor_id: form.value.investor_cid }
  delete payload.investor_cid

  payload.ownership_override = [null, undefined].includes(payload.ownership_override)
    ? null
    : payload.ownership_override / 100

  await props.onSubmit(payload)

  props.modalUtil.close()
  resetForm()
  loading.value = false
}
</script>

<template>
  <VSection label="Assign Ownership">
    <div class="flex h-full flex-col gap-6">
      <div class="flex-grow">
        <form @submit.prevent="submitForm">
          <div class="space-y-3">
            <VTextField
              v-model="form.ownership_override"
              label="Ownership Override"
              :placeholder="n(0, 'percent')"
              property="ownership_override"
              :v$="v$"
            />
          </div>
        </form>
      </div>
      <div class="flex items-center justify-between space-x-3">
        <VButton :click="modalUtil.close" size="lg">Close</VButton>
        <VButton :click="submitForm" class="w-full" :loading="loading" size="lg" variant="primary">
          Confirm Changes
        </VButton>
      </div>
    </div>
  </VSection>
</template>
