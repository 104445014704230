<script setup lang="ts">
import { get } from 'lodash'
import { computed, onMounted, ref } from 'vue'
import { useRoute, useRouter, RouterLink } from 'vue-router'
import { useSkeleton } from '@/modules/core/composables/useSkeleton'
import { useFetch as useFetchV3 } from '@/modules/core/stores/fetch'
import VSelectInvestor from '@/modules/investing/components/VSelectInvestor.vue'
import { useEntityStore } from '@/modules/investing/stores/better-entity-store'
import {
  ActionsGroup,
  ActionsMenu,
  ActionItem,
  VButtonGroup,
  VIcon,
  VSection,
  VTable,
} from '@/modules/shared/components'
import { rails_url } from '@/modules/shared/utils/rails'
import EntityLayout from '@/modules/investing/components/entities/entity-layout.vue'
import { VButton } from '@/modules/shared/components'
import { investorPath } from '@/modules/investing/utils/investor'
import { recycledDistributions } from '@/modules/investing/utils/distribution'
import { recycled_distribution_columns } from '@/modules/investing/config/columns'
import { useAuthStore } from '@/modules/auth/stores/auth-store'

///////////////////////////////////////////////////////////////////////////////
// Distributions
///////////////////////////////////////////////////////////////////////////////

const entityStore = useEntityStore()
const entity = computed(() => entityStore.entity)

const expandedDistributions = computed(() => {
  const distributions = entityStore.entity?.distributions || []
  const filteredDistributions = recycledDistributions(distributions)
  return (
    filteredDistributions.map((distribution) => {
      return {
        ...distribution,
        company: entityStore.getCompany(distribution.company_id),
        shareholder: entityStore.getShareholder(distribution.shareholder_id, distribution.shareholder_type),
      }
    }) || []
  )
})

const selectedCids = computed(() =>
  entityStore.selectedInvestorKeys.map((keys) => JSON.parse(keys).reverse().join(':')),
)

///////////////////////////////////////////////////////////////////////////////
// Actions
///////////////////////////////////////////////////////////////////////////////

const { entity_id, slug } = useRoute().params as { entity_id: string; slug: string }

const deleteDistribution = async (distributionId: string) => {
  if (!window.confirm('Are you sure?')) return
  await useFetchV3(`/${slug}/investing/b/entities/${entity_id}/distributions/${distributionId}/remove`).post()
  await fetch()
}

const fetch = async (shareholder_cids = null) => {
  showSkeleton()
  await entityStore.fetchDistributions(entity_id, { slug, shareholder_cids })
  hideSkeleton()
}

const onFilter = async () => {
  await fetch(selectedCids.value)
}

///////////////////////////////////////////////////////////////////////////////
// Authorization
///////////////////////////////////////////////////////////////////////////////

const authStore = useAuthStore()
const isAdmin = computed(
  () => authStore.is_site_or_group_admin || !!entityStore.getAdmin(authStore.current_user.investor_id),
)

///////////////////////////////////////////////////////////////////////////////
// Rails Utils
///////////////////////////////////////////////////////////////////////////////

const createCompanyUrl = (id: string) => {
  return `${rails_url()}/companies/${id}`
}

///////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////

const router = useRouter()
const { skeleton, hideSkeleton, showSkeleton } = useSkeleton()

onMounted(async () => {
  await fetch()
})
</script>

<template>
  <EntityLayout selectedTab="distributions">
    <VSection class="flex items-center justify-between">
      <VButtonGroup>
        <VSelectInvestor
          v-model="entityStore.selectedInvestorKeys"
          :investors="entityStore.listShareholders"
          :onClose="onFilter"
        />
        <template v-if="isAdmin">
          <RouterLink :to="{ name: 'investing.distributions' }">
            <VButton>
              <span>Sent to investors</span>
            </VButton>
          </RouterLink>
          <RouterLink :to="{ name: 'investing.distributions.recycled' }">
            <VButton :active="true">
              <span>Recycled Funds</span>
            </VButton>
          </RouterLink>
          <RouterLink :to="{ name: 'investing.portfolio.distributions' }">
            <VButton>
              <span>Received from portfolio</span>
            </VButton>
          </RouterLink>
        </template>
      </VButtonGroup>
      <RouterLink
        :to="{
          name: 'investing.disbursement.new',
          params: { entity_type: entity?.entity_type, entity_id: entity?.id },
        }"
        v-if="entity"
      >
        <VButton size="md" variant="v-blue">
          <div class="mr-1 flex items-center space-x-2">
            <div><VIcon name="plus" /></div>
            <div>Distribution</div>
          </div>
        </VButton>
      </RouterLink>
    </VSection>
    <VSection>
      <VTable
        :columns="recycled_distribution_columns"
        :items="expandedDistributions"
        :name="`entities-${1}-distributions`"
        :skeleton="skeleton"
      >
        <template #shareholder.name="{ item: distribution }">
          <RouterLink
            class="text-[#3b88af] underline decoration-[#3b88af]/50 hover:text-gray-900 hover:decoration-gray-900/50"
            :to="investorPath(get(distribution, 'shareholder.cid'))"
          >
            {{ get(distribution, 'shareholder.name') }}
          </RouterLink>
        </template>
        <template #company.name="{ item: distribution }">
          <a class="hyperlink" :href="createCompanyUrl(distribution.company_id)">
            {{ get(distribution, 'company.name') }}
          </a>
        </template>
        <template #actions="{ item: distribution }">
          <ActionsMenu v-if="distribution.type !== 'transfer distribution'">
            <ActionsGroup>
              <ActionItem
                @click="
                  () =>
                    router.push({
                      name: 'investing.disbursement.edit',
                      params: {
                        entity_type: entity?.entity_type,
                        entity_id: entity?.id,
                        disbursement_id: distribution.batch_id,
                      },
                    })
                "
                text="Edit"
                tag="a"
              />
              <ActionItem text="Remove" @click="deleteDistribution(distribution.id)" />
            </ActionsGroup>
          </ActionsMenu>
        </template>
      </VTable>
    </VSection>
  </EntityLayout>
</template>
