<script setup lang="ts">
import { format } from 'date-fns'
import { computed, ref, onMounted } from 'vue'
import { VTextField, VButton, VLoadingSection, VSection } from '@/modules/shared/components'
import { CommitmentExpanded, EntityExpanded } from '../../../stores/better-entity-store'
import { toNumber } from '@/modules/shared/utils/money'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { useInvestingInvestorStore } from '../../../stores/investor-store'
import { createOptions, markDisabledOptions } from '@/modules/shared/utils/form'
import VSelect from '../../v-select.vue'
import { useExtendedI18n } from '@/i18n'
import { initialMoney } from '@/modules/shared/utils/money'

const props = defineProps<{
  commitment: CommitmentExpanded
  entity: EntityExpanded
  disabledInvestorCIDs?: string[]
  modalUtil: any
  onSubmit: any
}>()

const { n } = useExtendedI18n()
const investorStore = useInvestingInvestorStore()
const skeleton = ref(true)
const isNewRecord = computed(() => !props.commitment?.id)
const isPersisted = computed(() => !isNewRecord.value)
const loading = ref(false)

const assignCommitmentDataToForm = () => {
  if (isNewRecord.value) return { ...initialState }

  return {
    id: props.commitment.id,
    capital: toNumber(props.commitment.committed), // temporary - form should use the currency field to handle money object
    carried_interest_percentage: props.commitment.carried_interest_percentage
      ? props.commitment.carried_interest_percentage * 100
      : null,
    date: format(new Date(props.commitment.date), 'yyyy-MM-dd'),
    investor_cid: props.commitment.shareholder.cid,
    management_fee_percentage: props.commitment.management_fee_percentage
      ? props.commitment.management_fee_percentage * 100
      : null,
    preferred_return_percentage: props.commitment.preferred_return_percentage
      ? props.commitment.preferred_return_percentage * 100
      : null,
  }
}
const resetForm = () => {
  v$.value.$reset()
  form.value = { ...initialState }
}
const initialState = {
  id: null,
  capital: null,
  carried_interest_percentage: props.entity.carried_interest_percentage,
  date: format(new Date(), 'yyyy-MM-dd'),
  investor_cid: null,
  management_fee_percentage: props.entity.management_fee_percentage,
  preferred_return_percentage: props.entity.preferred_return_percentage,
}
const investorOptions = computed(() => {
  // TODO
  const disabledInvestors = props.disabledInvestorCIDs || []

  return markDisabledOptions(createOptions(investorStore.investorItems, { label: 'name' }), disabledInvestors)
})

const form = ref(assignCommitmentDataToForm())
const rules = {
  capital: { required },
  date: { required },
  investor_cid: { required },
}
const v$ = useVuelidate(rules, form, { $lazy: true })

const submitForm = async () => {
  const valid = await v$.value.$validate()
  if (!valid) return

  loading.value = true

  // backend api expects investor_id, but in the current_implementation it actually passes CID not ID
  const payload = { ...form.value, investor_id: form.value.investor_cid }
  delete payload.investor_cid

  payload.carried_interest_percentage = (payload.carried_interest_percentage || 0) / 100
  payload.management_fee_percentage = (payload.management_fee_percentage || 0) / 100
  payload.preferred_return_percentage = (payload.preferred_return_percentage || 0) / 100

  await props.onSubmit(payload)

  if (isPersisted.value) props.modalUtil.close()
  resetForm()
  loading.value = false
}

onMounted(async () => {
  await investorStore.fetchInvestors()
  skeleton.value = false
})
</script>

<template>
  <VSection :label="isPersisted ? 'Modify investor' : 'Add investor'">
    <div class="flex h-full flex-col gap-6">
      <VLoadingSection
        v-if="skeleton"
        class="mt-40"
        title="Preparing Your Form..."
        description="Please wait a moment while we set things up for you. Your data is being processed to ensure everything runs smoothly."
      />
      <template v-else>
        <div class="flex-grow">
          <form @submit.prevent="submitForm">
            <div class="space-y-3">
              <VTextField v-model="form.date" label="Date" property="date" ref="autofocus" type="date" :v$="v$" />
              <VSelect
                v-model="form.investor_cid"
                label="Investor"
                :options="investorOptions"
                property="investor_cid"
                :v$="v$"
                :disabled="isPersisted"
              />
              <VTextField
                v-model="form.capital"
                label="Commitment"
                :placeholder="n(initialMoney, 'currency')"
                property="capital"
                :v$="v$"
              />
              <div>
                <hr class="my-8 h-1 bg-gray-100" />
              </div>
              <VTextField
                v-model="form.carried_interest_percentage"
                label="Carried Interest"
                :placeholder="n(0, 'percent')"
                property="carried_interest_percentage"
                :v$="v$"
              />
              <VTextField
                v-model="form.management_fee_percentage"
                label="Management Fee"
                :placeholder="n(0, 'percent')"
                property="management_fee_percentage"
                :v$="v$"
              />
              <VTextField
                v-model="form.preferred_return_percentage"
                label="Preferred Return"
                :placeholder="n(0, 'percent')"
                property="preferred_return_percentage"
                :v$="v$"
              />
            </div>
          </form>
        </div>
        <div class="flex items-center justify-between space-x-3">
          <VButton :click="modalUtil.close" size="lg">Close</VButton>
          <VButton :click="submitForm" class="w-full" :loading="loading" size="lg" variant="primary">
            <span v-if="isNewRecord">Add Investor</span>
            <span v-else="isNewRecord">Modify Investor</span>
          </VButton>
        </div>
      </template>
    </div>
  </VSection>
</template>
