<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import EntityLayout from '@/modules/investing/components/entities/entity-layout.vue'
import { useAuthStore } from '@/modules/auth/stores/auth-store'
import { EntityExpanded, useEntityStore } from '@/modules/investing/stores/better-entity-store'
import { useFetch } from '@/modules/core/composables/useFetch'
import { useRoute } from 'vue-router'
import { useSkeleton } from '@/modules/core/composables/useSkeleton'
import VButton from '@/modules/shared/components/v-button.vue'
import { VIcon, VLoadingSection, VSection, VTable } from '@/modules/shared/components'
// LEGACY
import { useI18n } from 'vue-i18n'
import { capitalize, get } from 'lodash'
import { Column, calculate_aggregate } from '@/modules/shared/utils/v-table'
import { Money } from '@/modules/shared/utils/money'
import { rails_url } from '@/modules/shared/utils/rails'
import { useCompanyStore } from '@/modules/investing/stores/company-store'
import { usePortfolioStore } from '@/modules/investing/stores/portfolio-store'
import { usePortfolioDistributionStore } from '@/modules/investing/stores/portfolio-distribution-store'

const authStore = useAuthStore()
const entityStore = useEntityStore()
const entity = computed(() => entityStore.entity)

///////////////////////////////////////////////////////////////////////////////
// Actions
///////////////////////////////////////////////////////////////////////////////

const { entity_id, slug } = useRoute().params as { entity_id: string; slug: string }

const fetch = async () => {
  const entityData = await useFetch(`/v4/${slug}/investing/investor-sets/${entity_id}`).get<EntityExpanded>()
  entityStore.entity = entityData
}

///////////////////////////////////////////////////////////////////////////////
// Authorization
///////////////////////////////////////////////////////////////////////////////

const isAdmin = computed(() => true)

///////////////////////////////////////////////////////////////////////////////
// LEGACY
///////////////////////////////////////////////////////////////////////////////
const { t } = useI18n()
const companyStore = useCompanyStore()
const portfolioStore = usePortfolioStore()
const portfolioDistributionStore = usePortfolioDistributionStore()
const current_portfolio_distributions = computed(() => portfolioDistributionStore.portfolio_distributions)
const loss_investments = computed(() => portfolioStore.loss_investments)

const fetchPortfolioDistribution = async () => {
  await portfolioDistributionStore.fetchPortfolioDistributions(entity.value?.entity_type, entity.value?.id)
}

const fetchLossInvestments = async () => {
  await portfolioStore.fetchLossInvestments(entity.value?.entity_type, entity.value?.id)
}

const aggregate_column = (key, type, aggregate): Column => {
  return {
    key,
    name: null,
    type,
    align: 'right',
    is_visible: true,
    aggregate,
  }
}

const generateAllocation = (allocation, keys: string[]) => {
  let data = {
    ...allocation,
    share_price: ['Cash', 'Dividend'].includes(allocation.type)
      ? { amount: 0, common_amount: 0, currency: 'USD', common_currency: 'USD', date: null }
      : allocation.share_price,
  }
  keys.forEach((key) => {
    const aggregate = calculate_aggregate(allocation.investors, aggregate_column(key, 'currency', 'sum')) as Money

    data[key] = {
      amount: aggregate.amount,
      common_amount: aggregate.common_amount,
      currency: aggregate.currency,
      common_currency: aggregate.common_currency,
      date: null,
    }
  })

  return data
}

const equity_allocations = computed(() =>
  current_portfolio_distributions.value
    .filter((allocation) => allocation.term_sheet?.investment_type === 'Equity')
    .map((allocation) => generateAllocation(allocation, ['total_received'])),
)

const convertible_note_allocations = computed(() =>
  current_portfolio_distributions.value
    .filter((allocation) => allocation.term_sheet?.investment_type === 'Convertible Note')
    .map((allocation) => generateAllocation(allocation, ['total_received', 'total_principal', 'total_interest'])),
)

const safe_note_allocations = computed(() =>
  current_portfolio_distributions.value
    .filter((allocation) => allocation.term_sheet?.investment_type === 'Safe Note')
    .map((allocation) => generateAllocation(allocation, ['total_received', 'total_principal', 'total_interest'])),
)

const loan_allocations = computed(() =>
  current_portfolio_distributions.value
    .filter((allocation) => allocation.term_sheet?.investment_type === 'Loan')
    .map((allocation) => generateAllocation(allocation, ['total_received', 'total_principal', 'total_interest'])),
)

const revenue_share_allocations = computed(() =>
  current_portfolio_distributions.value
    .filter((allocation) =>
      !allocation.term_sheet
        ? allocation.related_to === 'Revenue Share' || allocation.type === 'Revenue'
        : allocation.term_sheet.investment_type === 'Revenue Share',
    )
    .map((allocation) => generateAllocation(allocation, ['total_received'])),
)

const portfolio_distributions_data = computed((): { label: string; columns: Column[]; items: any[] }[] => [
  {
    label: capitalize(t('shared.equity')),
    columns: [
      {
        key: 'name',
        name: capitalize(t('shared.term sheet')),
        type: 'string',
        align: 'left',
        is_visible: true,
      },
      {
        key: 'date',
        name: capitalize(t('shared.date')),
        sorted: true,
        type: 'date',
        align: 'right',
        is_visible: true,
      },
      {
        key: 'share_price',
        name: capitalize(t('shared.share price')),
        type: 'currency',
        align: 'right',
        is_visible: true,
      },
      {
        key: 'shares_sold',
        name: capitalize(t('shared.shares sold')),
        type: 'number',
        align: 'right',
        is_visible: true,
      },
      {
        key: 'total_received',
        name: capitalize(t('shared.total amount')),
        type: 'currency',
        align: 'right',
        is_visible: true,
      },
      {
        key: 'actions',
        name: '',
        type: 'actions',
        align: 'right',
        is_visible: true,
      },
    ],
    items: equity_allocations.value,
  },
  {
    label: capitalize(t('shared.convertible note')),
    columns: [
      {
        key: 'name',
        name: capitalize(t('shared.term sheet')),
        type: 'string',
        align: 'left',
        is_visible: true,
      },
      {
        key: 'date',
        name: capitalize(t('shared.date')),
        sorted: true,
        type: 'date',
        align: 'right',
        is_visible: true,
      },
      {
        key: 'total_principal',
        name: capitalize(t('shared.principal')),
        type: 'currency',
        align: 'right',
        is_visible: true,
      },
      {
        key: 'total_interest',
        name: capitalize(t('shared.interest')),
        type: 'currency',
        align: 'right',
        is_visible: true,
      },
      {
        key: 'total_received',
        name: capitalize(t('shared.total amount')),
        type: 'currency',
        align: 'right',
        is_visible: true,
      },
      {
        key: 'actions',
        name: '',
        type: 'actions',
        align: 'right',
        is_visible: true,
      },
    ],
    items: convertible_note_allocations.value,
  },
  {
    label: capitalize(t('shared.safe note')),
    columns: [
      {
        key: 'name',
        name: capitalize(t('shared.term sheet')),
        type: 'string',
        align: 'left',
        is_visible: true,
      },
      {
        key: 'date',
        name: capitalize(t('shared.date')),
        sorted: true,
        type: 'date',
        align: 'right',
        is_visible: true,
      },
      {
        key: 'total_principal',
        name: capitalize(t('shared.principal')),
        type: 'currency',
        align: 'right',
        is_visible: true,
      },
      {
        key: 'total_interest',
        name: capitalize(t('shared.interest')),
        type: 'currency',
        align: 'right',
        is_visible: true,
      },
      {
        key: 'total_received',
        name: capitalize(t('shared.total amount')),
        type: 'currency',
        align: 'right',
        is_visible: true,
      },
      {
        key: 'actions',
        name: '',
        type: 'actions',
        align: 'right',
        is_visible: true,
      },
    ],
    items: safe_note_allocations.value,
  },
  {
    label: capitalize(t('shared.loan')),
    columns: [
      {
        key: 'name',
        name: capitalize(t('shared.term sheet')),
        type: 'string',
        align: 'left',
        is_visible: true,
      },
      {
        key: 'date',
        name: capitalize(t('shared.date')),
        sorted: true,
        type: 'date',
        align: 'right',
        is_visible: true,
      },
      {
        key: 'total_principal',
        name: capitalize(t('shared.principal')),
        type: 'currency',
        align: 'right',
        is_visible: true,
      },
      {
        key: 'total_interest',
        name: capitalize(t('shared.interest')),
        type: 'currency',
        align: 'right',
        is_visible: true,
      },
      {
        key: 'total_received',
        name: capitalize(t('shared.total amount')),
        type: 'currency',
        align: 'right',
        is_visible: true,
      },
      {
        key: 'actions',
        name: '',
        type: 'actions',
        align: 'right',
        is_visible: true,
      },
    ],
    items: loan_allocations.value,
  },
  {
    label: capitalize(t('shared.revenue share')),
    columns: [
      {
        key: 'name',
        name: capitalize(t('shared.term sheet')),
        type: 'string',
        align: 'left',
        is_visible: true,
      },
      {
        key: 'date',
        name: capitalize(t('shared.date')),
        sorted: true,
        type: 'date',
        align: 'right',
        is_visible: true,
      },
      {
        key: 'total_received',
        name: capitalize(t('shared.payment')),
        type: 'currency',
        align: 'right',
        is_visible: true,
      },
      {
        key: 'actions',
        name: '',
        type: 'actions',
        align: 'right',
        is_visible: true,
      },
    ],
    items: revenue_share_allocations.value,
  },
])

const realizeLoss = async (company_id, investment_id) => {
  const confirm = window.confirm(
    'Realizing a portfolio loss will reflect the loss on the capital account. You can revert this once done.',
  )
  if (!confirm) return
  await companyStore.realizeLoss(company_id, investment_id)
  await fetchLossInvestments()
}

const undoRealizeLoss = async (company_id, investment_id) => {
  const confirm = window.confirm('Are you sure?')
  if (!confirm) return
  await companyStore.undoRealizeLoss(company_id, investment_id)
  await fetchLossInvestments()
}

///////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////

const { skeleton, hideSkeleton } = useSkeleton()

onMounted(async () => {
  await fetch()

  // LEGACY
  await Promise.all([fetchPortfolioDistribution(), fetchLossInvestments()])

  hideSkeleton()
})
</script>

<template>
  <EntityLayout selectedTab="distributions">
    <template #actions> </template>
    <div class="-mt-7 mb-5 flex items-center justify-between" v-if="isAdmin">
      <div class="flex items-center space-x-1.5">
        <RouterLink :to="{ name: 'investing.distributions' }">
          <VButton>
            <span>Sent to investors</span>
          </VButton>
        </RouterLink>
        <RouterLink :to="{ name: 'investing.distributions.recycled' }">
          <VButton>
            <span>Recycled Funds</span>
          </VButton>
        </RouterLink>
        <RouterLink :to="{ name: 'investing.portfolio.distributions' }">
          <VButton :active="true">
            <span>Received from portfolio</span>
          </VButton>
        </RouterLink>
      </div>
      <div class="flex items-center space-x-1.5">
        <RouterLink
          :to="{
            name: 'investing.disbursement.new',
            params: { entity_type: entity?.entity_type, entity_id: entity?.id },
          }"
          v-if="entity"
        >
          <VButton size="md" variant="v-blue">
            <div class="mr-1 flex items-center space-x-2">
              <div><VIcon name="plus" /></div>
              <div>Distribution</div>
            </div>
          </VButton>
        </RouterLink>
      </div>
    </div>
    <VLoadingSection
      v-if="skeleton"
      class="mt-40"
      title="Preparing Your Portfolio Distributions..."
      description="Please wait a moment while we set things up for you. Your data is being processed to ensure everything runs
    smoothly."
    />
    <template
      v-for="portfolio_distribution in portfolio_distributions_data"
      :key="portfolio_distribution.label"
      v-if="!skeleton"
    >
      <VSection v-if="portfolio_distribution.items.length > 0" :label="portfolio_distribution.label">
        <VTable
          :columns="portfolio_distribution.columns"
          :items="portfolio_distribution.items"
          :name="`InvestorSet-${entity_id}-portfolio_disbursements-equity`"
          :skeleton="skeleton"
          :slots="['actions', 'name']"
        >
          <template #name="{ item }">
            <a
              :href="`${rails_url()}/companies/${get(item, 'asset.id')}/disbursements`"
              v-if="isAdmin"
              class="hyperlink"
            >
              {{ get(item, 'asset.name') }} - {{ get(item, 'related_to') || get(item, 'term_sheet.name') }} -
              {{ get(item, 'type') }}
            </a>
            <div v-else>
              {{ get(item, 'asset.name') }} - {{ get(item, 'related_to') || get(item, 'term_sheet.name') }} -
              {{ get(item, 'type') }}
            </div>
          </template>
          <template #actions="{ item }" v-if="isAdmin">
            <div class="flex justify-end gap-3">
              <RouterLink
                :to="{
                  name: 'investing.disbursement.new',
                  params: { entity_type: entity?.entity_type, entity_id: entity?.id },
                  query: { portfolio_disbursement_allocation_id: get(item, 'id') },
                }"
                v-if="!get(item, 'investor_set_disbursement.id')"
              >
                <VButton size="xs"> Distribute or Recycle </VButton>
              </RouterLink>
              <RouterLink
                :to="{
                  name: 'investing.disbursement.edit',
                  params: {
                    disbursement_id: get(item, 'investor_set_disbursement.id'),
                    entity_type: entity?.entity_type,
                    entity_id: entity?.id,
                  },
                }"
                v-if="get(item, 'investor_set_disbursement.id')"
              >
                <VButton size="xs">
                  {{ capitalize(t('shared.view details')) }}
                </VButton>
              </RouterLink>
            </div>
          </template>
        </VTable>
      </VSection>
    </template>
    <VSection v-if="loss_investments.length > 0 && authStore.is_site_admin && !skeleton" label="Loss">
      <VTable
        :columns="[
          {
            key: 'name',
            name: capitalize(t('shared.term sheet')),
            type: 'string',
            align: 'left',
            fixed: true,
            is_visible: true,
          },
          {
            key: 'company',
            name: capitalize(t('shared.company')),
            type: 'string',
            align: 'left',
            is_visible: true,
          },
          {
            key: 'loss_value',
            name: capitalize(t('shared.amount')),
            type: 'currency',
            aggregate: 'sum',
            align: 'right',
            is_visible: true,
          },
          {
            key: 'actions',
            name: '',
            type: 'actions',
            align: 'right',
            is_visible: true,
          },
        ]"
        :items="loss_investments"
        :name="`InvestorSet-${entity_id}-loss-term-sheets`"
        :skeleton="skeleton"
        :slots="['actions', 'name', 'company']"
      >
        <template #name="{ item }">
          <a
            :href="`${rails_url()}/companies/${get(item, 'company.id')}/terms/${get(item, 'term_sheet.id')}`"
            v-if="isAdmin"
            class="hyperlink"
          >
            {{ get(item, 'term_sheet.name') }}
          </a>
          <div v-else>
            {{ get(item, 'term_sheet.name') }}
          </div>
        </template>
        <template #company="{ item }">
          <a
            :href="`${rails_url()}/companies/${get(item, 'company.id')}/disbursements`"
            v-if="isAdmin"
            class="hyperlink"
          >
            {{ get(item, 'company.name') }}
          </a>
          <div v-else>
            {{ get(item, 'company.name') }}
          </div>
        </template>
        <template #actions="{ item }" v-if="isAdmin">
          <div class="flex justify-end gap-3">
            <VButton
              size="xs"
              :click="() => undoRealizeLoss(get(item, 'company.id'), get(item, 'id'))"
              v-if="get(item, 'is_loss')"
            >
              {{ capitalize(t('shared.revert realized loss')) }}
            </VButton>
            <VButton size="xs" :click="() => realizeLoss(get(item, 'company.id'), get(item, 'id'))" v-else>
              {{ capitalize(t('shared.realize loss')) }}
            </VButton>
          </div>
        </template>
      </VTable>
    </VSection>
  </EntityLayout>
</template>
