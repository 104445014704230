<script setup lang="ts">
import { computed } from 'vue'
import { useExtendedI18n } from '@/i18n'
import { VBarChart } from '@/modules/shared/components'
import { divide } from '@/modules/shared/utils/math'
import { Money, toNumber } from '@/modules/shared/utils/money'

///////////////////////////////////////////////////////////////////////////////
// Global
///////////////////////////////////////////////////////////////////////////////

const { n } = useExtendedI18n()

///////////////////////////////////////////////////////////////////////////////
// Utils
///////////////////////////////////////////////////////////////////////////////

const ratio = (numerator: Money, denominator: Money) => {
  if (!numerator || !denominator) return 0.0
  if (toNumber(denominator) === 0.0) return 0.0
  return divide(toNumber(numerator), toNumber(denominator))
}

const toNumberOrZero = (money: Money) => {
  if (!money) return 0.0
  return toNumber(money)
}

///////////////////////////////////////////////////////////////////////////////

const props = defineProps<{
  commitmentTotals: {
    committed: Money
    commitmentRemaining: Money
  }
  callTotals: {
    outstanding: {
      called: Money
      capital: Money
      managementFee: Money
      otherFee: Money
    }
    settled: {
      called: Money
      capital: Money
      managementFee: Money
      otherFee: Money
    }
  }
  skeleton?: boolean
}>()

const denominator = computed<Money>(() => {
  const sortedValues = [
    props.commitmentTotals.committed,
    props.callTotals.settled.called,
    props.callTotals.outstanding.called,
  ].sort((a, b) => toNumberOrZero(b) - toNumberOrZero(a))

  return sortedValues[0]
})
</script>

<template>
  <div class="rounded-lg border border-gray-200 bg-white px-0 py-8 dark:border-[#161618] dark:bg-[#1B1B1F]">
    <VBarChart
      :segments="[
        {
          label: 'Committed',
          ratio: ratio(commitmentTotals.committed, denominator),
          style: 'bg-[#7ABCBF]',
          value: n(commitmentTotals.committed, 'currency'),
        },
        {
          label: 'Paid',
          ratio: ratio(callTotals.settled.called, denominator),
          style: 'bg-[#5C8DB4]',
          value: n(callTotals.settled.called, 'currency'),
          segments: [
            {
              label: 'Capital',
              ratio: ratio(callTotals.settled.capital, callTotals.settled.called),
              style: 'bg-[#5C8DB4]/50',
              value: n(callTotals.settled.capital, 'currency'),
            },
            {
              label: 'Management fee',
              ratio: ratio(callTotals.settled.managementFee, callTotals.settled.called),
              style: 'bg-[#5cb493]',
              value: n(callTotals.settled.managementFee, 'currency'),
            },
            {
              label: 'Other fee',
              ratio: ratio(callTotals.settled.otherFee, callTotals.settled.called),
              style: 'bg-[#5cb493]',
              value: n(callTotals.settled.otherFee, 'currency'),
            },
          ],
        },
        {
          label: 'Outstanding',
          ratio: ratio(callTotals.outstanding.called, denominator),
          style: 'bg-[#7b5bb3]',
          value: n(callTotals.outstanding.called, 'currency'),
          segments: [
            {
              label: 'Capital',
              ratio: ratio(callTotals.outstanding.capital, callTotals.outstanding.called),
              style: 'bg-[#7b5bb3]/75',
              value: n(callTotals.outstanding.capital, 'currency'),
            },
            {
              label: 'Management fee',
              ratio: ratio(callTotals.outstanding.managementFee, callTotals.outstanding.called),
              style: 'bg-[#5cb493]',
              value: n(callTotals.outstanding.managementFee, 'currency'),
            },
            {
              label: 'Other fee',
              ratio: ratio(callTotals.outstanding.otherFee, callTotals.outstanding.called),
              style: 'bg-[#5cb493]',
              value: n(callTotals.outstanding.otherFee, 'currency'),
            },
          ],
        },
        {
          label: 'Remaining',
          ratio: ratio(commitmentTotals.commitmentRemaining, denominator),
          style: 'bg-gray-300',
          value: n(commitmentTotals.commitmentRemaining, 'currency'),
        },
      ]"
      :skeleton="skeleton"
      :expanded="true"
    />
  </div>
</template>
