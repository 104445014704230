import NestedCallsPage from '../pages/entities/[entityId]/calls/index.vue'
import NestedDistributionsPage from '../pages/entities/[entityId]/distributions/index.vue'
import NestedDistributionsRecycledPage from '../pages/entities/[entityId]/distributions/recycled.vue'
import NestedOverviewPage from '../pages/entities/[entityId].vue'
import NestedInvestorsPage from '../pages/entities/[entityId]/investors/index.vue'
import NestedPortfolioPage from '../pages/entities/[entityId]/portfolio/index.vue'
import NestedPortfolioDistributionsPage from '../pages/entities/[entityId]/portfolio/distributions.vue'
import CallsPage from '../pages/calls-page.vue'
import CapitalCallEditPage from '../pages/capital-call-edit-page.vue'
import CapitalCallNewPage from '../pages/capital-call-new-page.vue'
import DisbursementEditPage from '../pages/disbursement-edit-page.vue'
import DisbursementNewPage from '../pages/disbursement-new-page.vue'
import DistributionsPage from '../pages/distributions-page.vue'
import DocumentNewPage from '../pages/document-new-page.vue'
import DocumentsPage from '../pages/documents-page.vue'
import EntitiesPage from '../pages/entities-page.vue'
import EntityCapitalAccountPage from '../pages/entity-capital-account-page.vue'
import EntityDocumentsPage from '../pages/entity-documents-page.vue'
import EntityDocumentNewPage from '../pages/entity-document-new-page.vue'
import EntityOverviewPage from '../pages/entity-overview-page.vue'
import EntityPortfolioPage from '../pages/entity-portfolio-page.vue'
import GuestsPage from '../pages/guests-page.vue'
import IndividualAccountPage from '../pages/individual-account-page.vue'
import IndividualBeneficiariesPage from '../pages/individual-beneficiaries-page.vue'
import IndividualContactsPage from '../pages/individual-contacts-page.vue'
import IndividualDocumentsPage from '../pages/individual-documents-page.vue'
import IndividualInvoicesPage from '../pages/individual-invoices-page.vue'
import IndividualOverviewPage from '../pages/individual-overview-page.vue'
import IndividualTaxesPage from '../pages/individual-taxes-page.vue'
import IndividualsImportPage from '../pages/individuals-import-page.vue'
import IndividualsPage from '../pages/individuals-page.vue'
import IndividualEditPage from '../pages/individual-edit-page.vue'
import InvestorsPage from '../pages/investors-page.vue'
import InvoicesPage from '../pages/invoices-page.vue'
import OtherEntitiesPage from '../pages/other-entities-page.vue'
import OtherEntityDistributionsPage from '../pages/other-entity-distribution-page.vue'
import OtherEntityDocumentsPage from '../pages/other-entity-documents-page.vue'
import OtherEntityEditPage from '../pages/other-entity-edit-page.vue'
import OtherEntityInvestorsPage from '../pages/other-entity-investors-page.vue'
import OtherEntityInvoicesPage from '../pages/other-entity-invoices-page.vue'
import OtherEntityNewPage from '../pages/other-entity-new-page.vue'
import OtherEntityOverviewPage from '../pages/other-entity-overview-page.vue'
import OtherEntityPortfolioPage from '../pages/other-entity-portfolio-page.vue'
import InvdividualPortfolioPage from '../pages/individual-portfolio-page.vue'
import SubgroupsPage from '../pages/subgroups-page.vue'
import SubgroupOverviewPage from '../pages/subgroup-overview-page.vue'
import OwnershipTransfersPage from '../pages/ownership-transfers-page.vue'
import PortfolioPage from '../pages/portfolio-page.vue'

export default [
  {
    component: NestedOverviewPage,
    name: 'entities.overview',
    path: '/:slug/investing/entities/:entity_id',
  },
  {
    component: NestedCallsPage,
    name: 'entities.calls',
    path: '/:slug/investing/entities/:entity_id/calls',
  },
  {
    component: NestedDistributionsPage,
    name: 'entities.distributions',
    path: '/:slug/investing/entities/:entity_id/distributions',
  },
  {
    component: NestedDistributionsRecycledPage,
    name: 'entities.distributions.recycled',
    path: '/:slug/investing/entities/:entity_id/distributions/recycled',
  },
  {
    component: NestedDistributionsRecycledPage,
    name: 'investing.distributions.recycled',
    path: '/:slug/investing/:entity_type/:entity_id/distributions/recycled',
  },
  {
    component: NestedInvestorsPage,
    name: 'entities.investors',
    path: '/:slug/investing/entities/:entity_id/investors',
  },
  {
    component: NestedPortfolioPage,
    name: 'entities.portfolio',
    path: '/:slug/investing/entities/:entity_id/portfolio',
  },
  {
    component: NestedPortfolioDistributionsPage,
    name: 'entities.portfolio.distributions',
    path: '/:slug/investing/entities/:entity_id/portfolio/distributions',
  },
  {
    component: NestedPortfolioDistributionsPage,
    name: 'investing.portfolio.distributions',
    path: '/:slug/investing/:entity_type/:entity_id/portfolio/distributions',
  },
  {
    component: NestedCallsPage,
    name: 'investing.calls',
    path: '/:slug/investing/:entity_type/:entity_id/calls',
  },
  {
    component: CapitalCallEditPage,
    name: 'investing.capital-call.edit',
    path: '/:slug/investing/:entity_type/:entity_id/calls/:capital_call_id/edit',
  },
  {
    component: CapitalCallNewPage,
    name: 'investing.capital-call.new',
    path: '/:slug/investing/:entity_type/:entity_id/calls/new',
  },
  {
    component: DisbursementEditPage,
    name: 'investing.disbursement.edit',
    path: '/:slug/investing/:entity_type/:entity_id/distributions/:disbursement_id/edit',
  },
  {
    component: DisbursementNewPage,
    name: 'investing.disbursement.new',
    path: '/:slug/investing/:entity_type/:entity_id/distributions/new',
  },
  {
    component: NestedDistributionsPage,
    name: 'investing.distributions',
    path: '/:slug/investing/:entity_type/:entity_id/distributions',
  },
  {
    component: DocumentNewPage,
    name: 'investing.document.new',
    path: '/:slug/investing/document/new',
  },
  {
    component: DocumentsPage,
    name: 'investing.documents',
    path: '/:slug/investing/documents',
  },
  {
    component: EntitiesPage,
    name: 'investing.entities',
    path: '/:slug/investing/:entity_type',
  },
  {
    component: EntityCapitalAccountPage,
    name: 'investing.entity.capital-account',
    path: '/:slug/investing/:entity_type/:entity_id/capital_account',
  },
  {
    component: EntityDocumentsPage,
    name: 'investing.entity.documents',
    path: '/:slug/investing/:entity_type/:entity_id/documents',
  },
  {
    component: EntityDocumentNewPage,
    name: 'investing.entity.document.new',
    path: '/:slug/investing/:entity_type/:entity_id/document/new',
  },
  {
    component: NestedOverviewPage,
    name: 'investing.entity-overview',
    path: '/:slug/investing/:entity_type/:entity_id/overview',
  },
  {
    component: GuestsPage,
    name: 'investing.guests',
    path: '/:slug/investing/guests',
  },
  {
    component: IndividualsPage,
    name: 'investing.individuals',
    path: '/:slug/investing/individuals',
  },
  {
    component: IndividualBeneficiariesPage,
    name: 'investing.individual.beneficiaries',
    path: '/:slug/investing/individual/:individual_id/beneficiaries',
  },
  {
    component: IndividualContactsPage,
    name: 'investing.individual.contacts',
    path: '/:slug/investing/individual/:individual_id/contacts',
  },
  {
    component: IndividualDocumentsPage,
    name: 'investing.individual.documents',
    path: '/:slug/investing/individual/:individual_id/documents',
  },
  {
    component: IndividualInvoicesPage,
    name: 'investing.individual-invoices',
    path: '/:slug/investing/individual/:individual_id/invoices',
  },
  {
    component: IndividualOverviewPage,
    name: 'investing.individual-overview',
    path: '/:slug/investing/individual/:individual_id/overview',
  },
  {
    component: IndividualTaxesPage,
    name: 'investing.individual.taxes',
    path: '/:slug/investing/individual/:individual_id/taxes',
  },
  {
    component: IndividualAccountPage,
    name: 'investing.individual.account',
    path: '/:slug/investing/individual/:individual_id/account',
  },
  {
    component: IndividualEditPage,
    name: 'investing.individual.edit',
    path: '/:slug/investing/individual/:individual_id/edit',
  },
  {
    component: IndividualsImportPage,
    name: 'investing.individuals.import',
    path: '/:slug/investing/individuals/import',
  },
  {
    component: NestedInvestorsPage,
    name: 'investing.investors',
    path: '/:slug/investing/:entity_type/:entity_id/investors',
  },
  {
    component: InvoicesPage,
    name: 'investing.invoices',
    path: '/:slug/investing/invoices',
  },
  {
    component: OtherEntitiesPage,
    name: 'investing.other-entities',
    path: '/:slug/investing/associated-entities',
  },
  {
    component: OtherEntityDistributionsPage,
    name: 'investing.other-entity-distributions',
    path: '/:slug/investing/associated-entity/:other_entity_id/distributions',
  },
  {
    component: OtherEntityDocumentsPage,
    name: 'investing.other-entity.documents',
    path: '/:slug/investing/associated-entity/:other_entity_id/documents',
  },
  {
    component: OtherEntityEditPage,
    name: 'investing.other-entity.edit',
    path: '/:slug/investing/associated-entity/:other_entity_id/edit',
  },
  {
    component: OtherEntityInvoicesPage,
    name: 'investing.other-entity.invoices',
    path: '/:slug/investing/associated-entity/:other_entity_id/invoices',
  },
  {
    component: OtherEntityInvestorsPage,
    name: 'investing.other-entity.investors',
    path: '/:slug/investing/associated-entity/:other_entity_id/investors',
  },
  {
    component: OtherEntityNewPage,
    name: 'investing.other-entity.new',
    path: '/:slug/investing/associated-entity/new',
  },
  {
    component: OtherEntityOverviewPage,
    name: 'investing.other-entity.overview',
    path: '/:slug/investing/associated-entity/:other_entity_id/overview',
  },
  {
    component: OtherEntityPortfolioPage,
    name: 'investing.other-entity.portfolio',
    path: '/:slug/investing/associated-entity/:other_entity_id/portfolio',
  },
  {
    component: SubgroupsPage,
    name: 'investing.subgroups',
    path: '/:slug/investing/subgroups',
  },
  {
    component: SubgroupOverviewPage,
    name: 'investing.subgroup-overview',
    path: '/:slug/investing/subgroup/:subgroup_id/overview',
  },
  {
    component: OwnershipTransfersPage,
    name: 'investing.transfers',
    path: '/:slug/investing/:entity_type/:entity_id/transfers',
  },
  {
    component: PortfolioPage,
    name: 'investing.portfolio',
    path: '/:slug/portfolio',
  },
  {
    component: InvdividualPortfolioPage,
    name: 'investing.individual.portfolio',
    path: '/:slug/investing/individual/:individual_id/portfolio',
  },
  {
    component: NestedPortfolioPage,
    name: 'investing.entity.portfolio',
    path: '/:slug/investing/:entity_type/:entity_id/portfolio',
  },
  {
    path: '/:slug/investing/:entity_type/:entity_id',
    redirect: (to) => {
      return { name: 'investing.entity-overview', params: to.params }
    },
  },
  {
    path: '/:slug/investing/individual/:individual_id',
    redirect: (to) => {
      return { name: 'investing.individual-overview', params: to.params }
    },
  },
  {
    path: '/:slug/investing/associated-entity/:other_entity_id',
    redirect: (to) => {
      return { name: 'investing.other-entity.overview', params: to.params }
    },
  },
]
